import mixins from 'vue-typed-mixins'
import Navigation from '@/calendesk/mixins/Navigation'

export default mixins(Navigation).extend({
  data () {
    return {
      isLoading: false
    }
  }
})
